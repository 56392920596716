import React from "react";

function NotFoundPage() {
  return (
    <>
      <h2>Page Not Found</h2>
      <p>We are sorry but the page you are looking for could not be found. </p>
    </>
  );
}
export default NotFoundPage