import React from "react";

function Intex () {
    return(
        <div className="h-full container mx-auto bg-opacity-50 bg-repeat drop-shadow-lg">
            <img className="mx-auto block" src="images/kahanamoku.png" alt="kahanamoku"/>
        </div>
    )
}

export default Intex