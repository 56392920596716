import React, { useState } from "react";
import { tableHeaders, user, currentMonth } from "../../testData";

function Overview() {
  const [hide, setHide] = useState(false);
  const [monthData, setMonthData] = useState(currentMonth);

  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const d = new Date();
  const month = monthNames[d.getMonth()];

  const daysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  };

  const isWeekend = (i: number) => {
    const newDate = new Date();
    newDate.setDate(i);
    if (!(newDate.getDay() % 6)) {
      return true;
    } else {
      return false;
    }
  };

  const renderSwitch = (i: number, t: string, weekend: boolean) => {
    const disabled = ["Urlaub_Krank_Etc", "Gesamtstunden", "TagesSaldo", "Gutstunden", "Überstunden", "Sodexo"];
    if (t === "Tag") {
      return (
        <input
          disabled
          className={weekend ? "bg-blue-50" : ""}
          value={(monthData[i] as any)[t]}
          name={t}
          onChange={() => handleChange(window.event, i)}
        />
      );
    }
    if (disabled.includes(t) && user.role !== "admin") {
      return (
        <input
          disabled
          className={weekend ? "bg-blue-50" : ""}
          value={(monthData[i] as any)[t]}
          name={t}
          onChange={() => handleChange(window.event, i)}
        />
      );
    } else {
      return (
        <input
          className={weekend ? "bg-blue-50" : ""}
          value={(monthData[i] as any)[t]}
          name={t}
          onChange={() => handleChange(window.event, i)}
        />
      );
    }
  };

  const handleHeader = (header: string) => {
    return (
      <th
        key={header}
        scope="col"
        className={`${
          (header === "Gutstunden" || header === "Überstunden" || header === "Sodexo") && hide ? "more" : ""
        } sticky z-10 top-0 px-4 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 uppercase tracking-wider`}
      >
        {header}
      </th>
    );
  };

  const handleChange = (event: any, i: any) => {
    const { name, value } = event.target;
    return setMonthData((prevData) => ({
      ...prevData,
      [i]: {
        ...prevData[i],
        [name]: value,
      },
    }));
  };

  return (
    <div className="px-2 flex flex-col">
      <div>
        <div>
          <img className="py-3 px-4 logo" src="images/logo.png" alt="logo" width="250" />
        </div>
        <div className="px-4 py-3 text-center text-xl font-medium text-gray-500 tracking-wider">Stundenaufstellung</div>
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Monat: {month}</div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
            Mitarbeiter: {user.name}
          </div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
            Stunden pro Woche: {user.weeklyHours}
          </div>
        </div>
        <div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
            Kunde: {user.customer}
          </div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
            Projekt: {user.project}
          </div>
          <div className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">UT noch: {user.ut}</div>
        </div>
      </div>
      <div className="-my-2 h-screen">
        <div className="py-2 align-middle inline-block w-full sm:px-2 lg:px-4">
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  {tableHeaders.map((t) => handleHeader(t))}
                  <th onClick={() => setHide(!hide)}>
                    {hide ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                        <path d="M20 12l-2.83 2.83 9.17 9.17-9.17 9.17 2.83 2.83 12-12z" />
                        <path d="M0 0h48v48h-48z" fill="none" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                        <path d="M30.83 14.83l-2.83-2.83-12 12 12 12 2.83-2.83-9.17-9.17z" />
                        <path d="M0 0h48v48h-48z" fill="none" />
                      </svg>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody id="tbody" className="bg-white divide-y divide-gray-200">
                {[...Array(daysInMonth(d.getMonth(), d.getFullYear()))].map((e, i) => (
                  <tr key={i} className={isWeekend(i) ? "bg-blue-50" : ""}>
                    {tableHeaders.map((t) => (
                      <td className="px-4 py-2 whitespace-nowrap" style={{ height: "75px" }} key={t}>
                        {renderSwitch(i, t.replace(/[, ]+/g, "_"), isWeekend(i))}
                      </td>
                    ))}
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Overview;
