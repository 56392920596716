import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Intex from "./Kahanamoku.Api/pages/Index";
import NotFoundPage from "./Kahanamoku.Api/pages/NotFoundPage";
import Overview from "./Kahanamoku.Api/pages/Overview";
import "tailwindcss/tailwind.css"

const App: React.FC = () => {
  return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Intex} />
          <Route path="/overview" component={Overview} />
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
  );
};

export default App;
