const tableHeaders = [
    "Tag",
    "Anfang",
    "Pause Anfang",
    "Pause Ende",
    "Ende",
    "Urlaub, Krank, Etc",
    "Gesamtstunden",
    "Tätigkeitsbeschreibung",
    "Wo gearbeitet",
    "TagesSaldo +/-",
    "Gutstunden",
    "Überstunden",
    "Sodexo",
  ];


const user = {
        id: 1,
        name: "Ohlong Johnson",
        customer: "Netrixa Internal",
        weeklyHours: 38.5,
        project: "Kahanamoku",
        ut: 17,
        role: null,
}

const currentMonth = [ 
     {
        Tag: 1,
        Anfang: 830,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 2,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "Test",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 3,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 4,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 5,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 6,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 7,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 8,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 9,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 10,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 11,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 12,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 13,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 14,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 15,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 16,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 17,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 18,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 19,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 20,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 21,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 22,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 23,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 24,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 25,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 26,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 27,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 28,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },        
    {
        Tag: 29,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
    {
        Tag: 30,
        Anfang: 800,
        Pause_Anfang: 1200,
        Pause_Ende: 1230,
        Ende: 1800,
        Urlaub_Krank_Etc: 0,
        Gesamtstunden: 9,
        Tätigkeitsbeschreibung: "",
        Wo_gearbeitet: "",
        TagesSaldo: 0,
        Gutstunden: 0,
        Überstunden: 0,
        Sodexo: 0
    },
]

module.exports = {
    tableHeaders,
    user,
    currentMonth  
} 